import moment from 'moment';
import * as PainelGeralService from '../pages/ISA/InfoPanel/InfoPanel/services';
import { getGroupBy, getOrder } from '../pages/ISA/InfoPanel/InfoPanel/utils';

export const ADD_FILTRO_PANEL = 'ADD_FILTRO_PANEL';
export const DELETE_FILTRO_PANEL = 'DELETE_FILTRO_PANEL';
export const CHANGE_VALUE_PANEL = 'CHANGE_VALUE_PANEL';
export const CHANGE_CACHE_PANEL = 'CHANGE_CACHE_PANEL';
export const SELECT_VALUE_PANEL = 'SELECT_VALUE_PANEL';
export const LIST_ENDERECO = 'LIST_ENDERECO';
export const DELETE_FILTRO_ENDERECO = 'DELETE_FILTRO_ENDERECO';
export const LIST_PRODUTOS = 'LIST_PRODUTOS';
export const SET_LAT_LON = 'SET_LAT_LON';
export const SET_ERRO_PAINEL_GERAL = 'SET_ERRO_PAINEL_GERAL';
export const LIST_ITENS_FILTROS_PANEL = 'LIST_ITENS_FILTROS_PANEL';
export const SET_DATA = 'SET_DATA';
export const SET_LIMIT_PRODUCTS = 'SET_LIMIT_PRODUCTS';
export const SET_FILTROS_SALVOS = 'SET_FILTROS_SALVOS';
export const CLEAR_FILTROS = 'CLEAR_FILTROS';
export const CLEAR_FILTROS_DINAMICOS = 'CLEAR_FILTROS_DINAMICOS';
export const SET_DATA_BOXPLOT = 'SET_DATA_BOXPLOT';
export const SET_VISUALIZATION_TYPE_BOXPLOT = 'SET_VISUALIZATION_TYPE_BOXPLOT';
export const SET_SORT_COLUMN_BOXPLOT = 'SET_SORT_COLUMN_BOXPLOT';
export const SET_DATA_TIME_EVOLUTION = 'SET_DATA_TIME_EVOLUTION';
export const SET_VISUALIZATION_TYPE_TIME_EVOLUTION =
    'SET_VISUALIZATION_TYPE_TIME_EVOLUTION';
export const SET_VISUALIZATION_TYPE_MAP = 'SET_VISUALIZATION_TYPE_MAP';
export const SET_DATA_MAP = 'SET_DATA_MAP';
export const SET_MARKERS_DATA = 'SET_MARKERS_DATA';
export const SET_PERIODO_TIME_EVOLUTION = 'SET_PERIODO_TIME_EVOLUTION';
export const SET_TOP_FIVE_DATA = 'SET_TOP_FIVE_DATA';
export const SET_SHARE_TOKENS_DATA = 'SET_SHARE_TOKENS_DATA';
export const SET_USERS_EMAIL_DATA = 'SET_USERS_EMAIL_DATA';
export const SET_TOTAL_DATAPOINTS = 'SET_TOTAL_DATAPOINTS';
export const SET_CHANNEL = 'SET_CHANNEL';
export const SET_PRICE_FILTER = 'SET_PRICE_FILTER';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const SET_AD = 'SET_AD';
export const SET_PRODUCT_DASHBOARD_LIST = 'SET_PRODUCT_DASHBOARD_LIST';
export const CHANGE_ADD_PRODUCT_PANEL = 'CHANGE_ADD_PRODUCT_PANEL';
export const CHANGE_REMOVE_PRODUCT_PANEL = 'CHANGE_REMOVE_PRODUCT_PANEL';
export const CHANGE_CHECK_ALL_PRODUCT = 'CHANGE_CHECK_ALL_PRODUCT';
export const SET_SINGLE_REDUCER = 'SET_SINGLE_REDUCER';
export const SET_CUSTOM_FILTER_LIST = 'SET_CUSTOM_FILTER_LIST';
export const CHANGE_NO_CACHE_VALUE = 'CHANGE_NO_CACHE_VALUE';
export const CLEAR_FILTROS_PANEL = 'CLEAR_FILTROS_PANEL';
export const SET_FILTROS_SALVOS_PANEL = 'SET_FILTROS_SALVOS_PANEL';
export const SET_LIMIT_PRODUCTS_FILTROS_DATA =
    'SET_LIMIT_PRODUCTS_FILTROS_DATA';
export const SET_PERMITION = 'SET_PERMITION';
export const CLEAR_TOTALDATAPOINT = 'CLEAR_TOTALDATAPOINT';

export function addFiltro(filtro) {
    return {
        type: ADD_FILTRO_PANEL,
        filtro,
    };
}

export function deleteFiltro(index, name) {
    return {
        type: DELETE_FILTRO_PANEL,
        index,
        name,
    };
}

export function changeValue(value, name) {
    return {
        type: CHANGE_VALUE_PANEL,
        value,
        name,
    };
}

export function changeCache(name, cache) {
    return {
        type: CHANGE_CACHE_PANEL,
        name,
        cache,
    };
}

export function selectValue(item, name) {
    return {
        type: SELECT_VALUE_PANEL,
        item,
        name,
    };
}

export function setSingleReducer(name, value, item) {
    return {
        type: SET_SINGLE_REDUCER,
        item,
        value,
        name,
    };
}

export function setCustomFilterList(name, data) {
    return {
        type: SET_CUSTOM_FILTER_LIST,
        data,
        name,
    };
}

export function changeAddProduct(item) {
    return {
        type: CHANGE_ADD_PRODUCT_PANEL,
        item,
    };
}

export function changeRemoveProduct(value) {
    return {
        type: CHANGE_REMOVE_PRODUCT_PANEL,
        value,
    };
}

export function changeCheckAllProduct(item, value) {
    return {
        type: CHANGE_CHECK_ALL_PRODUCT,
        item,
        value,
    };
}

export function selectValueNoCache(value, name) {
    return {
        type: CHANGE_VALUE_PANEL,
        value,
        name,
    };
}

export function listItens(itens, name) {
    const newList = itens.map((item) => ({
        label: item.key,
        value: item.key,
        datapoints: item.datapoints,
    }));

    return {
        type: LIST_ITENS_FILTROS_PANEL,
        itens: newList,
        name,
    };
}

export function listEndereco(list) {
    return {
        type: LIST_ENDERECO,
        list,
    };
}

export function deleteFiltroEndereco(index) {
    return {
        type: DELETE_FILTRO_ENDERECO,
        index,
    };
}

export function listProdutos(list, identificadores) {
    return {
        type: LIST_PRODUTOS,
        list,
        identificadores,
    };
}

export function setLatLon(data) {
    return {
        type: SET_LAT_LON,
        data,
    };
}

export function setDataPanel(data) {
    return {
        type: SET_DATA,
        data,
    };
}

export function setLimitProducts(value) {
    return {
        type: SET_LIMIT_PRODUCTS,
        value,
    };
}

export function setLimitProductsFiltrosData(value) {
    return {
        type: SET_LIMIT_PRODUCTS_FILTROS_DATA,
        value,
    };
}

export function setErro(message, name) {
    return {
        type: SET_ERRO_PAINEL_GERAL,
        message,
        name,
    };
}

export function setFiltrosSalvos(name, value) {
    return {
        type: SET_FILTROS_SALVOS,
        name,
        value,
    };
}

export function setFiltrosSalvosPanel(name, value) {
    return {
        type: SET_FILTROS_SALVOS_PANEL,
        name,
        value,
    };
}

export function clearFiltros() {
    return {
        type: CLEAR_FILTROS,
    };
}

export function clearTotalDatapoints() {
    return {
        type: CLEAR_TOTALDATAPOINT,
    };
}

export function clearFiltrosPanel(list) {
    return {
        type: CLEAR_FILTROS_PANEL,
        list,
    };
}

export function clearFiltrosDinamicos() {
    return {
        type: CLEAR_FILTROS_DINAMICOS,
    };
}

export function setDataBoxPlot(data) {
    return {
        type: SET_DATA_BOXPLOT,
        data,
    };
}

export function setVisualizationTypeBoxplot(visualizationType) {
    return {
        type: SET_VISUALIZATION_TYPE_BOXPLOT,
        visualizationType,
    };
}

export function setSortColumnBoxplot(sortColumn) {
    return {
        type: SET_SORT_COLUMN_BOXPLOT,
        sortColumn,
    };
}

export function setPermition() {
    return {
        type: SET_PERMITION,
    };
}

export function setDataTimeEvolution(data) {
    return {
        type: SET_DATA_TIME_EVOLUTION,
        data: {
            content: data?.content?.sort(
                (a, b) => new Date(a.dia_semana) - new Date(b.dia_semana),
            ),
            totalsResult: data?.totalsResult,
            pageable: data?.pageable,
            number: data?.number,
            numberOfElements: data?.numberOfElements,
            totalElements: data?.totalElements,
            totalPages: data?.totalPages,
            size: data?.size,
        },
    };
}

export function setVisualizationTypeTimeEvolution(visualizationType) {
    return {
        type: SET_VISUALIZATION_TYPE_TIME_EVOLUTION,
        visualizationType,
    };
}

export function setPeriodoTimeEvolution(periodo) {
    return {
        type: SET_PERIODO_TIME_EVOLUTION,
        periodo,
    };
}

export function setVisualizationTypeMap(visualizationType) {
    return {
        type: SET_VISUALIZATION_TYPE_MAP,
        visualizationType,
    };
}

export function setDataMap(data) {
    return {
        type: SET_DATA_MAP,
        data,
    };
}

export function setMarkersData(data) {
    return {
        type: SET_MARKERS_DATA,
        data,
    };
}

export function setTopFiveData(data) {
    return {
        type: SET_TOP_FIVE_DATA,
        data,
    };
}

export function setShareTokensData(data) {
    return {
        type: SET_SHARE_TOKENS_DATA,
        data,
    };
}

export function setUsersEmailData(data) {
    return {
        type: SET_USERS_EMAIL_DATA,
        data,
    };
}

export function setTotalDatapoints(data) {
    return {
        type: SET_TOTAL_DATAPOINTS,
        data,
    };
}

export function setChannel(data) {
    return {
        type: SET_CHANNEL,
        data,
    };
}

export function setAd(data) {
    return {
        type: SET_AD,
        data,
    };
}

export function setPriceFilter(data) {
    return {
        type: SET_PRICE_FILTER,
        data,
    };
}

export function setLoadingStatus(data) {
    return {
        type: SET_LOADING_STATUS,
        data,
    };
}

export function setProductDashboardList(name, data) {
    return {
        type: SET_PRODUCT_DASHBOARD_LIST,
        name,
        data,
    };
}

export function changeNoCacheValue(name, value) {
    return {
        type: CHANGE_NO_CACHE_VALUE,
        name,
        value,
    };
}

export function searchEndereco(data) {
    return (dispatch) => {
        if (data.query) {
            PainelGeralService.getEndereco(data).then((data) =>
                dispatch(listEndereco(data)),
            );
        }
    };
}

export function searchProdutos(data, size) {
    return (dispatch) =>
        PainelGeralService.getProduto(data, size).then((response) => {
            if (response) {
                dispatch(listProdutos(response, data.identificadores));
            }
        });
}

export function getLatLon(query) {
    return (dispatch) => {
        PainelGeralService.getPlace(query).then((data) =>
            dispatch(setLatLon(data.result)),
        );
    };
}

export function setFirstViewWithCookie() {
    return (dispatch) => {
        dispatch(
            setDataPanel([
                moment().subtract(30, 'days').toDate(),
                moment().toDate(),
            ]),
        );
        return true;
    };
}

export function getItensFiltro(params, name, painel) {
    return (dispatch) => {
        PainelGeralService.getItensFiltros(params).then((data) => {
            if (data) {
                if (painel === 'painel') {
                    dispatch(setCustomFilterList(name, data));
                } else {
                    dispatch(listItens(data, name));
                }
            }
        });
    };
}

export function getBoxPlotData(filter, type, pagination, order) {
    const group = getGroupBy(type);
    const orderBy = order || getOrder(type);

    return (dispatch) => {
        dispatch(setLoadingStatus({ name: 'loadingBoxplot', status: true }));
        PainelGeralService.getAgregado(
            { filter, group, order: orderBy },
            pagination,
        ).then((response) => {
            dispatch(setDataBoxPlot(response));
        });
    };
}

function getGroupByTimeEvolution(type, periodo) {
    switch (type) {
        case 'produto':
            return ['GTIN', periodo];
        case 'rede':
            return ['REDE', periodo];
        case 'tipoLoja':
            return ['TIPO_LOJA', periodo];
        case 'fabricante':
            return ['FABRICANTE', periodo];
        case 'semAgrupamento':
            return [periodo];
        default:
            return [periodo];
    }
}

function getOptions(type) {
    switch (type) {
        case 'produto':
            return {
                top_k_results: {
                    maximum_results: 10,
                    columns: ['GTIN'],
                },
            };
        case 'rede':
            return {
                top_k_results: {
                    maximum_results: 10,
                    columns: ['REDE'],
                },
            };
        default:
            return null;
    }
}

export function getTimeEvolutionData(filter, agrupadores) {
    const group = getGroupByTimeEvolution(
        agrupadores.type,
        agrupadores.periodo,
    );
    const options = getOptions(agrupadores.type);
    return (dispatch) => {
        dispatch(
            setLoadingStatus({ name: 'loadingTimeEvolution', status: true }),
        );
        PainelGeralService.getAgregadoWithCount(
            { filter, group, options },
            { size: 1000 },
        ).then((response) => {
            dispatch(setDataTimeEvolution(response));
        });
    };
}

function getGroupByMap() {
    return ['UF'];
}

export function getMapData(filter) {
    const group = getGroupByMap();

    return (dispatch) => {
        dispatch(setDataMap(null));
        dispatch(setLoadingStatus({ name: 'loadingMap', status: true }));
        PainelGeralService.getAgregado({ filter, group }, { size: 10000 }).then(
            (response) => {
                dispatch(setDataMap(response));
            },
        );
    };
}

export function getMarkersData(filter) {
    return (dispatch) => {
        dispatch(setLoadingStatus({ name: 'loadingMap', status: true }));
        PainelGeralService.getPinPoints({ filter }).then((response) => {
            dispatch(setMarkersData(response));
        });
    };
}

export function getTopFiveData(filter) {
    const group = getGroupBy();
    const order = getOrder();
    return (dispatch) => {
        dispatch(setLoadingStatus({ name: 'loadingTopFive', status: true }));
        PainelGeralService.getTopFive({ filter, order, group }).then(
            (response) => {
                dispatch(setTopFiveData(response));
            },
        );
    };
}

export function getShareTokenData(filter, chartsData) {
    const groupC1 = getGroupByTimeEvolution(
        chartsData.timeEvolution.type,
        chartsData.timeEvolution.periodo,
    );
    const optionsC1 = getOptions(chartsData.timeEvolution.type);
    const groupC2 = getGroupBy(chartsData.boxplot.type);
    const orderC2 = getOrder(chartsData.boxplot.type);
    const groupC3 = chartsData.map.type === 'estadual' ? ['UF'] : null;
    const orderC4 = getOrder();

    const c1 = {
        group: groupC1,
        options: optionsC1,
    };

    const c2 = {
        group: groupC2,
        order: orderC2,
    };

    const c3 = {
        group: groupC3,
    };

    const c4 = {
        order: orderC4,
    };

    return (dispatch) => {
        PainelGeralService.getShareTokens({
            f: filter,
            c1,
            c2,
            c3,
            c4,
        }).then((response) => {
            dispatch(setShareTokensData(response));
        });
    };
}

export function getUsersEmailData(name) {
    return (dispatch) => {
        PainelGeralService.getUsersEmail(name).then((response) => {
            dispatch(setUsersEmailData(response));
        });
    };
}

export function getTotalDatapoints(filters) {
    return (dispatch) => {
        PainelGeralService.getDataCount(filters).then((response) => {
            dispatch(setTotalDatapoints(response));
        });
    };
}

export function getProductDashboardSlide(filters, pageData) {
    return (dispatch) => {
        dispatch(
            setLoadingStatus({ name: 'loadingProductDashboard', status: true }),
        );

        PainelGeralService.productDashboardService(filters, pageData).then(
            (response) => {
                if (response.status === 200) {
                    dispatch(
                        setProductDashboardList(
                            'productDashboardList',
                            response.data,
                        ),
                    );
                    dispatch(
                        setLoadingStatus({
                            name: 'loadingProductDashboard',
                            status: false,
                        }),
                    );
                } else {
                    dispatch(
                        setLoadingStatus({
                            name: 'loadingProductDashboard',
                            status: false,
                        }),
                    );
                }
            },
        );
    };
}

export function getProductDashboardTable(filters, pageData) {
    return (dispatch) => {
        dispatch(
            setLoadingStatus({
                name: 'loadingProductDashboardTable',
                status: true,
            }),
        );

        PainelGeralService.productDashboardService(filters, pageData).then(
            (response) => {
                if (response.status === 200) {
                    dispatch(
                        setProductDashboardList(
                            'productDashboardTableList',
                            response.data,
                        ),
                    );
                    dispatch(
                        setLoadingStatus({
                            name: 'loadingProductDashboardTable',
                            status: false,
                        }),
                    );
                } else {
                    dispatch(
                        setLoadingStatus({
                            name: 'loadingProductDashboardTable',
                            status: false,
                        }),
                    );
                }
            },
        );
    };
}
